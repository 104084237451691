header {
  height: 100vh;
  padding-top: 3rem;
  overflow: hidden;
}

.header__container {
  text-align: center;
  height: 100%;
  position: relative;
}

.header__text {
  width: 70%;   
  margin: 0 auto;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 3rem;
}

/* ==================== CTA ===================== */
.cta {
  margin-top: 2.5rem;
  display: flex;
  gap: 1.2rem;
  justify-content: center;
}

/* ==================== HEADER SOCIALS ===================== */
.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 20rem;
  font-size: xxx-large;
}

.header__socials::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

/* ==================== ME IMAGE ===================== */
.me {
  /* background: linear-gradient(var(--color-primary), transparent); */
  width: 22rem;
  height: 22rem;
  position: relative;
  left: calc(50% - 11rem);
  border-radius: 6%;
  overflow: hidden;
  /* padding: 1.5rem 1.5rem 1.5rem 1.5rem; */
  
}

/* ==================== SCROLL DOWN ===================== */
.scroll__down {
  position: absolute;
  right: -2.3rem;
  bottom: 27rem;
  transform: rotate(90deg);
  font-weight: 300;
  /* font-size: 0.9rem; */
  font-size: xxx-large;

}

/* ================= MEDIA QUERIES (MEDIUM DEVICES) ================= */
@media screen and (max-width: 1024px) {
  header {
    height: 100vh;
  }
}

/* ================= MEDIA QUERIES (SMALL DEVICES) ================= */
@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }
  .header__socials,
  .scroll__down {
    display: none;
  }
}
